export default {
    path: '/content',
    component: () => import('@/views/layout'),
    redirect: '/content_cate',
    meta: {
        title: '内容管理',
        icon: 'dict'

    },
    children: [
        {
            path: '/content_tag',
            component: () => import('@/views/content/tag'),
            meta: {
                title: '标签管理',
                icon: 'link'
            },
        },
        {
            path: '/content_article',
            component: () => import('@/views/content/article'),
            meta: {
                title: '文章管理',
                icon: 'language'
            },
        },
        {
            path: '/content_cate',
            component: () => import('@/views/content/cate'),
            meta: {
                title: '分类管理',
                icon: 'tree'
            },
        },


    ]
}