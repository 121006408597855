import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import store from '@/store'
import '@/styles/transition.scss'
import './permission'
import '@/assets/font/iconfont.css'
import '@/icons'
import '@/assets/font-awesome-4.7.0/css/font-awesome.min.css'
import VueContextMenu from 'vue-contextmenu'
import ScreenFull from '@/components/ScreenFull'
Vue.component('ScreenFull', ScreenFull) 
Vue.use(VueContextMenu)
import * as filters from '@/filters'
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
