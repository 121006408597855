export default {
    path: '/user',
    component: () => import('@/views/layout'),
    meta: {
        title: '会员管理',
        icon: 'user'
    },
    redirect: '/user_user',
    children: [
        {
            path: '/user_user',
            component: () => import('@/views/user/user'),
            meta: {
                title: '会员列表',
                icon: 'role'
            },
        }
    ]
}