import Vue from "vue";
import VueRouter from "vue-router";
import SystemRoute from './modules/system'
import UserRoute from './modules/user'
import ContentRoute from './modules/content'
Vue.use(VueRouter)
export const constRoute = [
    {
        path: '/',
        component: () => import('@/views/layout'),
        title: '优点达',
        redirect: '/dashboard',
        meta: {
            title: '后台主页',
            icon: 'dashboard'
        },
        children: [
            {
                path: "/dashboard",
                component: () => import('@/views/dashboard'),
                meta: {
                    title: '后台主页'
                },
            },
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        hidden: true,
        meta: {
            title: '登录'
        },
    },
    {
        path: '/404',
        hidden: true,
        component: () => import('@/views/notfound'),
    },

]
export const asyncRoute = [SystemRoute, UserRoute, ContentRoute]
const router = new VueRouter({
    routes: [...asyncRoute,...constRoute]
})
export function resetRouter() {
    const newRouter = new VueRouter({
        routes: [...constRoute]
    })
    router.matcher = newRouter.matcher
}
export default router