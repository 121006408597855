import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import setting from './modules/setting'
import permission from './modules/permission'
Vue.use(Vuex)
const store = new Vuex.Store({
    strict: false,
    state: {},
    mutations: {},
    getters: {},
    actions: {},
    modules: {
        user: user,
        setting: setting,
        permission
    }
})
export default store