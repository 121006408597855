<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

  components: {},
};
</script>

<style>
/* body {
  /* background: url("./assets/loginbg.png") !important; */

* {
  margin: 0;
  padding: 0;
}
</style>
