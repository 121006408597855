<template>
  <el-row class="login_centauner" type="flex" justify="center" align="middle">
    <div class="banner">
      <el-col :span="6" class="login_box">
        <i class="min_logo"></i>
        <h3>用户登录</h3>
        <el-form
          label-width="60px"
          :model="formData"
          :rules="formRules"
          ref="formRef"
        >
          <el-form-item label="账号" prop="username">
            <el-input
              v-model="formData.username"
              placeholder="请输入内容"
              prefix-icon="el-icon-user"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="formData.password"
              placeholder="请输入内容"
              prefix-icon="el-icon-lock"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button
              :loading="isloading"
              class="login_btn"
              type="primary"
              @click="BtnLoginOk"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </div>
  </el-row>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      formData: {
        username: "root@admin.com",
        password: "123456",
      },
      isloading: false,
      formRules: {
        login_name: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { min: 5, max: 16, message: "长度在6-16之间", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      pw: "",
    };
  },

  methods: {
    async BtnLoginOk() {
      try {
        await this.$refs.formRef.validate();
      } catch (error) {
        return console.log(error);
      }
      // this.isloading = true;
      // let sha256 = require("js-sha256").sha256;
      // this.pw = sha256(this.formData.password);
      // await this.$store.dispatch("user/loginAction", {
      //   username: this.formData.username,
      //   password: this.pw,
      // });
      this.$router.push("/");
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.login_centauner {
  background: url("~@/assets/bg.2f46505a.jpg");
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #2d3a4b;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.login_box {
  position: absolute;
  left: 20%;
  top: 50%;
  width: 520px;
  padding: 15px 35px 15px 35px;
  margin: -200px 0 0 0;
  background: #f5f5f5;
}
.min_logo {
  position: absolute;
  top: 32px;
  display: inline-block;
  width: 35px;
  height: 26px;
  background: url("~@/assets/min-logo.png") no-repeat;
}
.banner {
  height: 100%;
  width: 100%;
  position: relative;
  background: url("~@/assets/logingBg.989212c0.png") no-repeat 100% 100%;
}
.login_box h3 {
  /* text-align: center; */
  color: #000;
  font-weight: normal;
  font-size: 22px;
  line-height: 60px;
  margin-bottom: 15px;
  margin-left: 50px;
}
.login_btn {
  display: block;
  width: 100%;
  height: 50px;
  font-size: 22px;
}
</style>
