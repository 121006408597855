<template>
  <div v-if="!obj.hidden">
    <el-menu-item
      :class="obj.path == '/' ? '' : 'menuItem'"
      v-if="
        obj.path == '/' ||
        obj.children === undefined ||
        obj.children.length === 0
      "
      :index="obj.path === '/' ? '/dashboard' : obj.path"
    >
      <Item :icon="obj.meta.icon" :title="obj.meta.title" />
    </el-menu-item>
    <el-submenu ref="subMenu" class="submenu" v-else :index="`${ind + 1}`">
      <template slot="title">
        <Item :icon="obj.meta.icon" :title="obj.meta.title" />
      </template>
      <SideItem
        v-for="(item, ind) in obj.children"
        :key="ind"
        :obj="item"
        :ind="ind"
      />
    </el-submenu>
  </div>
</template>

<script>
import Item from "./Item.vue";
export default {
  name: "SideItem",
  components: {
    Item,
  },
  props: {
    obj: {
      type: Object,
      default: "",
    },
    ind: {
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.svg-icon {
  margin-right: 15px;
}
.submenu {
  /deep/.menuItem {
    background-color: #1f2d3d !important;
  }
  /deep/.menuItem:hover {
    background-color: #001528 !important;
  }
}
</style>
