<template>
  <div>
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      style="height: 60px"
    >
      <el-row
        class="header-left"
        type="flex"
        align="middle"
        style="width: 500px"
      >
        <el-button
          icon="el-icon-s-unfold"
          v-if="isCollapse"
          @click="toggleCollapse"
          size="small"
        />
        <el-button
          v-else
          icon="el-icon-s-fold"
          @click="toggleCollapse"
          size="small"
        />

        &nbsp;&nbsp;
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <transition-group name="breadcrumb">
            <el-breadcrumb-item
              v-for="(obj, ind) in locationList"
              :key="obj.path"
            >
              <router-link
                v-if="ind < locationList.length - 1"
                :to="obj.path"
                >{{ obj.title }}</router-link
              >
              <span v-if="!ind < locationList.length - 1">{{ obj.title }}</span>
            </el-breadcrumb-item>
          </transition-group>
        </el-breadcrumb>
      </el-row>
      <div>
        <el-row type="flex" align="middle">
          <theme-setting />
          <screen-full class="right-menu-item" />

          <div class="heder-right">
            <el-avatar
              icon="el-icon-user-solid"
              style="margin-right: 10px"
            ></el-avatar>
            <el-dropdown>
              <span class="el-dropdown-link">
                找粮<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>个人中心</el-dropdown-item>
                <el-dropdown-item>布局设置</el-dropdown-item>
                <el-dropdown-item divided @click.native="quit"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-row>
      </div>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { ThemeSetting } from "@/components/index";
import settings from "../../../../../config/settings";

export default {
  components: {
    ThemeSetting,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("setting", ["isCollapse"]),
    locationList() {
      return this.$route.matched.map((item) => ({
        title: item.meta.title,
        path: item.path,
      }));
    },
  },
  methods: {
    ...mapMutations("setting", ["toggleCollapse"]),
    async quit() {
      try {
        await this.$confirm("是否确认退出", "提示");
      } catch (error) {
        return;
      }
      this.$store.dispatch("user/quitOut");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../components/index.scss";

.layout-header {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  .el-dropdown-link {
    line-height: 60px;
    color: $--my-text-color85 !important;
  }
  .el-avatar {
    vertical-align: middle;
  }
}
.right-menu-item {
  height: 60px;
  line-height: 60px;
  vertical-align: middle; /*  修改为middle */
  margin: 0 20px;
  ::v-deep .svg-icon {
    width: 20px !important;
    height: 20px !important;
    line-height: 60px !important;
    color: $--my-text-color85 !important;
    vertical-align: middle !important;
  }
}
::v-deep .el-breadcrumb__inner {
  color: $--my-text-color85 !important;
}
</style>
