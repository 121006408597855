<template>
  <div>
    <el-container class="layout-container">
      <el-aside
        class="layout-aside"
        :width="$store.state.setting.isCollapse ? '64px' : '220px'"
      >
        <SideBar />
      </el-aside>
      <el-container>
        <el-header class="layout-header">
          <NavBar />
        </el-header>
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <router-link
            @contextmenu.native="showMenu($event, ind)"
            v-for="(obj, ind) in path"
            :key="ind"
            :to="obj.path"
            class="tags-view-item"
            :active-class="
              obj.path == $route.path || path.length == 1 ? 'active' : ''
            "
            >{{ obj.meta.title }}
            <i class="el-icon-close" @click.prevent="delLink($event, ind)"></i>
          </router-link>
          <vue-context-menu
            class="context-menu"
            :contextMenuData="contextMenuData"
            @close="close"
            @closeall="closeall"
            @closeelse="closeelse"
          >
          </vue-context-menu>
        </el-scrollbar>
        <el-main class="layout-main">
          <transition name="fade-transform" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import SideBar from "./components/SideBar";
export default {
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {
      path: [],
      contextMenuData: {
        menuName: "demo",
        axis: {
          x: null,
          y: null,
        },
        active: null,
        ind: null,
        menulists: [
          {
            fnHandler: "close",
            btnName: "关闭",
          },
          {
            fnHandler: "closeelse",
            btnName: "关闭其他",
          },
          {
            fnHandler: "closeall",
            btnName: "关闭所有",
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (this.path.length === 0) {
          this.path.push(val);
        } else {
          const isRepeat = this.path.some((ele) => ele.path === val.path);
          if (!isRepeat) {
            this.path.push(val);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    delLink(e, val) {
      if (
        e.target.parentNode.className ===
          "tags-view-item router-link-exact-active active" &&
        this.path.length !== 1
      ) {
        this.$nextTick(() => {
          this.$router.push(this.path[this.path.length - 1].path);
        });
      }
      this.path.splice(val, 1);
      this.$nextTick(() => {
        if (this.path.length === 0 && this.$route.path !== "/dashboard") {
          this.$router.push("/dashboard");
        }
      });
    },
    showMenu(e, ind) {
      event.preventDefault();
      var x = event.clientX;
      var y = event.clientY;
      this.contextMenuData.axis = {
        x,
        y,
      };
      this.contextMenuData.ind = ind;
      this.contextMenuData.active = e;
    },
    close() {
      console.log(this.contextMenuData.active.target, this.contextMenuData.ind);
      if (
        this.contextMenuData.active.target.className ===
          "tags-view-item router-link-exact-active active" &&
        this.path.length !== 1
      ) {
        this.$nextTick(() => {
          this.$router.push(this.path[this.path.length - 1].path);
        });
      }
      this.path.splice(this.contextMenuData.ind, 1);
      this.$nextTick(() => {
        if (this.path.length === 0 && this.$route.path !== "/dashboard") {
          this.$router.push("/dashboard");
        }
      });
    },
    closeelse() {
      this.path = [this.path[this.contextMenuData.ind]];
      this.$router.push(
        this.contextMenuData.active.target.attributes["href"].value.substring(1)
      );
    },
    closeall() {
      this.path = [];
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../components/index.scss";
.layout-container {
  height: 100vh;
  background-color: #eaedf1;
}

.layout-main {
  margin-top: 20px;
  overflow-x: hidden;
}
.layout-aside {
  background-color: #304156;
  .logo {
    height: 60px;
    line-height: 60px;
    background-color: #2b2f3a;
    h3 {
      text-align: center;
      color: #fff;
      font-weight: normal;
      font-size: 18px;
    }
  }
}
.el-scrollbar {
  position: fixed;
  top: 60px;
  overflow-x: hidden;
  z-index: 1999;
  width: 100%;
  background-color: #fff;
  padding-bottom: 25px;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden !important;
}
.tags-view-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  text-decoration: none;
  border: 1px solid #d8dce5;
  border-bottom: 0;
  color: #495060;
  background: #fff;
  padding: 0 8px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 4px;
}
.tags-view-item:first-of-type {
  margin-left: 15px;
}
.tags-view-item.active {
  background-color: #42b983;
  color: #fff;
  border-color: #42b983;
}
.tags-view-item.active::before {
  content: "";
  background: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 2px;
}
.el-scrollbar__view {
  padding-bottom: 5px;
}
::v-deep.layout-header {
  position: static !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background: $--my-background-color1 !important;
  border-bottom: 1px solid #f3f4f5;
  .el-dropdown-link {
    line-height: 60px;
  }
  .el-avatar {
    vertical-align: middle;
  }
}
.context-menu {
  width: 80px;
  padding: 0;
}
::v-deep .context-menu-list,
.btn-wrapper-simple {
  height: 30px !important;
  > div {
    height: 100% !important;
  }
}
::v-deep .nav-name-right {
  margin: 0 !important;
  font-size: 12px;
  color: #333 !important;
  font-family: "微软雅黑";
}
::v-deep .context-menu-list:hover {
  background: #eeeeee !important;
}
::v-deep .no-child-btn {
  height: 100% !important;

  padding: 5px 16px !important;
}
::v-deep .el-scrollbar {
  background: $--my-background-color1 !important;
}
</style>
