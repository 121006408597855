import request from "@/utils/request";
export const loginApi = data => request({
    url: '/admin/index/login',
    method: 'post',
    data
})
export const getUserProfileApi = () => request({
    url: '/admin/index/profile',
    method: 'post'
})
export const getUserListApi = params => request({
    url: '/admin/ydd_user/list',
    params
})
export const addUserApi = data => request({
    url: '/admin/ydd_user/add',
    method: 'post',
    data
})
export const editUserApi = data => request({
    url: '/admin/ydd_user/update',
    method: 'post',
    data
})
export const getUserInfoApi = params => request({
    url: '/admin/ydd_user/rowInfo',
    params
})
export const delUserApi = params => request({
    url: '/admin/ydd_user/del',
    params
})