import axios from 'axios'
import { getToken } from './auth'
import { Message } from 'element-ui'
const request = axios.create({
    baseURL: process.env.VUE_APP_ApiUrl,
    timeout: 60000
})
request.interceptors.request.use(config => {
    const token = getToken()
    if (token) config.headers['x-token'] = token
    return config
}), error => {
    return Promise.reject(error)
}
request.interceptors.response.use(response => {
    const { data, errmsg, errno } = response.data
    if (errno === 0) {
        return data
    } else {
        Message.error(errmsg)
        return Promise.reject(new Error(errmsg))
    }
}), error => {
    return Promise.reject(error)
}
export default request