<template>
  <div>Dict</div>
</template>

<script>
export default {
  name: "Dict",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
