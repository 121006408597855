import { getToken } from "./utils/auth";
import router from "./router";
import store from "./store";
const WhiteList = ['/404', '/login']
router.beforeEach(async (to, from, next) => {
    next()
    // if (getToken()) {
    //     if (to.path === '/login') {
    //         next('/')
    //     } else {
    //         if (!store.getters['user/userId']) {
    //             const res = await store.dispatch('user/getUserProfileAction')
    //             const routes = await store.dispatch('permission/filterRoutes', res)
    //             router.addRoutes([...routes, {
    //                 path: '/*',
    //                 hidden: true,
    //                 redirect: '/404'
    //             }])
    //             next(to.path)
    //         } else {
    //             next()
    //         }
    //     }
    // } else {
    //     if (WhiteList.includes(to.path)) {
    //         next()
    //     } else {
    //         next('/login')
    //     }
    // }
})