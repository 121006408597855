<template>
  <div>
    <div class="sidebar-logo-container" :class="{ collapse: isCollapse }">
      <transition name="sidebarLogoFade">
        <router-link
          v-if="isCollapse"
          key="collapse"
          class="sidebar-logo-link"
          to="/"
        >
          <img v-if="logo" :src="logo" class="sidebar-logo" />
          <h1 v-else class="sidebar-title">{{ title }}</h1>
        </router-link>
        <router-link v-else key="expand" class="sidebar-logo-link" to="/">
          <img v-if="logo" :src="logo" class="sidebar-logo" />
          <h1 class="sidebar-title">{{ title }}</h1>
        </router-link>
      </transition>
    </div>
    <el-menu
      :collapse="$store.state.setting.isCollapse"
      :collapse-transition="false"
      :default-active="$route.path"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#ff7f50"
      router
    >
      <SideItem
        v-for="(route, ind) in routes"
        :key="ind"
        :obj="route"
        :ind="ind"
      />
    </el-menu>
  </div>
</template>

<script>
import SideItem from "./SideItem.vue";

export default {
  components: {
    SideItem,
  },
  data() {
    return {
      title: "Vue Admin Template",
      logo: "https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png",
    };
  },
  computed: {
    isCollapse() {
      return this.$store.state.setting.isCollapse;
    },
    routes() {
      console.log(this.$store.state.permission.routes);
      return this.$store.state.permission.routes;
    },
  },
};
</script>

<style scoped lang="less">
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
.layout-aside {
  background-color: #304156;
  .el-menu {
    border-right: none;
  }
  .logo {
    height: 60px;
    line-height: 60px;
    background-color: #2b2f3a;
    h3 {
      text-align: center;
      color: #fff;
      font-weight: normal;
      font-size: 18px;
    }
  }
  /deep/.svg-icon {
    width: 1em;
    height: 1em;
  }
}
/deep/.el-menu--collapse .el-submenu > .el-submenu__title > span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
/deep/.el-menu--collapse
  .el-submenu
  > .el-submenu__title
  .el-submenu__icon-arrow {
  display: none;
}
</style>
