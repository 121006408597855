import { asyncRoute, constRoute } from '@/router'
export default {
    namespaced: true,
    state: {
        // routes: constRoute
        routes: [...constRoute, ...asyncRoute]
    },
    mutations: {
        setRoutes(state, newRoutes) {
            state.routes = [...constRoute, ...newRoutes]
        }
    },
    getters: {

    },
    actions: {
        filterRoutes(context, data) {
            let userRoutes = []
            data.forEach(async ele => {
                if (ele.is_show === 0) {
                    ele.hidden = true
                }
                try {
                    await require('@/views/' + ele.component).default
                    ele.component = require('@/views/' + ele.component).default
                    ele.meta = { title: ele.title, icon: ele.icon }
                } catch (error) {
                    ele.component = {}
                    if (ele.icon !== '') {
                        ele.meta = { title: '新增菜单：' + ele.title, icon: ele.icon }
                    } else {
                        ele.meta = { title: '新增菜单：' + ele.title, icon: 'newadd' }
                    }
                }
                ele.children = data.filter(item => item.pid === ele.id && item.type === 1)
            })
            userRoutes = data.filter(item => item.pid === 0)
            console.log(userRoutes);
            context.commit('setRoutes', userRoutes)
            return userRoutes
        }
    }
}