import { loginApi, getUserProfileApi } from '@/api/User'
import { setToken, getToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
export default {
    namespaced: true,
    state: {
        token: getToken(),
        userInfo: {}
    },
    mutations: {
        setToken(state, data) {
            state.token = data
            setToken(data)
        },
        getUserInfo(state, data) {
            state.userInfo = data
        },
        removeToken(state) {
            state.token = null
            removeToken()
        }
    },
    getters: {
        userId: state => state.userInfo.id
    },
    actions: {
        async loginAction(context, data) {
            const res = await loginApi(data)
            context.commit('setToken', res.token)
        },
        async getUserProfileAction({ commit }) {
            const { userInfo, menuList } = await getUserProfileApi()
            commit('getUserInfo', userInfo)
            return menuList
        },
        quitOut({ commit }) {
            commit('removeToken')
            commit('getUserInfo', {})
            resetRouter()
            commit('permission/filterRoutes', [], { root: true })
        }
    }
}