<template>
  <div class="theme-setting">
    <i class="el-icon-setting" @click="openSetting" />
    <el-dialog
      :modal-append-to-body="false"
      title="主题设置"
      :visible.sync="dialogTableVisible"
    >
      <div class="setting-container">
        <div class="primary-color-setting">
          <div
            v-for="(item, index) in colorList"
            :key="index"
            class="item"
            :style="`background-color:${item.color}`"
            @click="changePrimaryColor(item.color)"
          />
        </div>
        <div class="theme-setting">
          <div @click="changeTheme('light')">亮色系</div>
          <div @click="changeTheme('dark')">暗色系</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ThemeSetting",
  props: {},
  data() {
    return {
      colorList: [
        {
          key: "莓红",
          color: "#C45A65",
        },
        {
          key: "玫瑰粉",
          color: "#f8b37f",
        },
        {
          key: "葵扇黄",
          color: "#e8b004",
        },
        {
          key: "蛙绿",
          color: "#45b787",
        },
        {
          key: "碧青",
          color: "#5cb3cc",
        },
        {
          key: "宝石蓝",
          color: "#2486b9",
        },
        {
          key: "洋葱紫",
          color: "#a8456b",
        },
      ],
      dialogTableVisible: false,
    };
  },
  computed: {},
  methods: {
    openSetting() {
      this.dialogTableVisible = true;
    },
    changeTheme(theme) {
      this.$store.dispatch("setting/toggleTheme", theme);
    },
    changePrimaryColor(color) {
      this.$store.dispatch("setting/togglePrimaryColor", color);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "index";
</style>
